import React, {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";
import Footer from "../component/components/Footer";

const Layout = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener("resize", handleWindowResize);
    
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    return (
        <div className="flex flex-col h-full">
            <div className="contents">
                <div className="flex-auto" style={{height: innerHeight-32}}>
                    <Outlet />
                </div>
                <div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default Layout;